.question-container {
  /* background-color: green;
  border: 1px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.question-box {
  background: #0d141c;
  border: 3px solid #ffffff;
  border-radius: 26px;
}

.answers-row-1 {
  background-color: #0d141c;
  height: 85px;
  width: 800px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.answers-col-1 {
  background-color: #0d141c;
  height: 85px;
  width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 7px;
  padding-bottom: 14px;
}

.answers-col-2 {
  background-color: #0d141c;
  height: 85px;
  width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 7px;
  margin-top: 21px;
  margin-bottom: 21px;
}

.test-question {
  min-height: 100vh;
  background-color: aqua;
}

.answers-row-2 {
  background-color: #0d141c;
  height: 85px;
  width: 800px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
