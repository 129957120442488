.question-text-container {
  height: 170px;
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.question-text {
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  width: 80%;
  line-height: 42px;
  word-spacing: 0.06cm;
  text-align: center;
}
