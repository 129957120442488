.game-summary-container {
  background-color: #0d141c;
  height: 350px;
  width: 600px;
  border: 3px solid #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-self: center;
  border-radius: 26px;
  gap: 10px;
}

.final-score-and-summary-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: center;
}

.summary-text,
.final-score {
  color: #ef5a7a;
  line-height: 1cm;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  text-align: center;
}

img {
  padding-top: 5px;
}

.leaderboard-button {
  padding-top: 25px;
}
