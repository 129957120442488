.main-screen {
  background-color: aqua;
  border: 1px solid black;
  width: 93%;
  display: flex;
  flex-direction: column;
}

.question-form-and-sidebar-container {
  background-color: #0d141c;
  height: 95%;
  display: flex;
  justify-content: center;
  gap: 10px;
}
