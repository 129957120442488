* {
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background: white;
  color: black;
}

.main-container {
  display: flex;
  min-width: 100%;
  min-height: 100vh;
}

